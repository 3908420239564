import secureStorageUtils from 'utils/secureStorage';
import { SecureStorageEnum } from 'enums/auth';

const getVesselId = () =>
  secureStorageUtils.getItem(SecureStorageEnum.VESSEL_ID) || '';

const actionUtils = {
  getVesselId,
};

export default actionUtils;
