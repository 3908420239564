import { SystemInfoDocTypeEnum } from 'enums/systemInfo';
import { SystemInfoActionTypeEnum } from '../../enums/actions';
import {
  SystemInfoActionTypes,
  SystemInfoReducerState,
} from '../../types/systemInfoStore';

export const initialState: SystemInfoReducerState = {
  latestDateCreated: '',
};

export const systemInfoReducer = (
  state = initialState,
  action: SystemInfoActionTypes,
): SystemInfoReducerState => {
  switch (action.type) {
    case SystemInfoActionTypeEnum.READ_LATEST_ACTIVITY_SUCCESS:
      return {
        ...state,
        latestDateCreated: action.payload?.[SystemInfoDocTypeEnum.Activities],
      };
    default:
      return state;
  }
};
