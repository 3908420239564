import { DashboardActionTypeEnum } from 'enums/actions';
import { InventoryStatisticsTypeEnum } from 'enums/inventory';
import { DAYS_ON_BOARD_MAX_VALUE } from 'constants/ui';
import { RootStateType as IStore } from '../../types/store';

// AppReducer
export const sTaskStatus = (key: string) => (store: IStore) =>
  store.asyncTaskReducer.status[key];

// LoginReducer
export const sAuthenticated = (store: IStore) => store.loginReducer.status;
export const sLoginUser = (store: IStore) => store.loginReducer.user;
export const sVessels = (store: IStore) => store.loginReducer.vessels;

// Dashboard Reducer
// Get POs pending delivery data
export const sGetPendingDeliveryData = (store: IStore) =>
  store.dashboardReducer.pendingDelivery?.data;
export const sGetPendingDeliveryError = (store: IStore) =>
  store.dashboardReducer.pendingDelivery?.error;
export const sGetPendingDeliveryRequest = (store: IStore) =>
  store.dashboardReducer.pendingDelivery?.request;
export const sGetPendingDeliveryPagination = (store: IStore) => ({
  pageNumber: store.dashboardReducer.pendingDelivery?.data?.pageNumber,
  recordsPerpage: store.dashboardReducer.pendingDelivery?.data?.recordsPerpage,
  totalPages: store.dashboardReducer.pendingDelivery?.data?.totalPages,
});

export const sTagsUsedData = (store: IStore) =>
  store.dashboardReducer.tagsUsed?.data?.value;
export const sTagsUsedToDate = (store: IStore) =>
  store.dashboardReducer.tagsUsed.toDate;
export const sTagsUsedFromDate = (store: IStore) =>
  store.dashboardReducer.tagsUsed.fromDate;
export const sTagStatData = (store: IStore) =>
  store.dashboardReducer.tagStat.data;
export const sTagStatParams = (store: IStore) =>
  store.dashboardReducer.tagStat.params;

export const sTagAllTimeParams = (store: IStore) =>
  store.dashboardReducer.tagAllTime.params;

export const sTagAllTimeData = (store: IStore) =>
  store.dashboardReducer.tagAllTime.data;

export const sSpareCountByStatus = (store: IStore) =>
  store.dashboardReducer.spareCountByStatus?.value || 0;

// return true if the api of the pages is in progress
export const sGetDashBoardApiLoading = (store: IStore) =>
  store.dashboardReducer.pendingDelivery?.request?.apiRequest ||
  store.dashboardReducer.criticalSpares?.request?.apiRequest ||
  store.asyncTaskReducer.status[DashboardActionTypeEnum.TAG_STAT_SUCCESS]
    ?.processing ||
  store.asyncTaskReducer.status[DashboardActionTypeEnum.TAGS_USED_REQUEST]
    ?.processing;
// get the criticals spares data
export const sGetCriticalSparesData = (store: IStore) =>
  store.dashboardReducer.criticalSpares?.data;
export const sGetCriticalSparesError = (store: IStore) =>
  store.dashboardReducer.criticalSpares?.error;
export const sGetCriticalSparesRequest = (store: IStore) =>
  store.dashboardReducer.criticalSpares?.request;
export const sGetCriticalSparesPagination = (store: IStore) => ({
  pageNumber: store.dashboardReducer.criticalSpares?.data?.pageNumber,
  recordsPerpage: store.dashboardReducer.criticalSpares?.data?.recordsPerpage,
  totalPages: store.dashboardReducer.criticalSpares?.data?.totalPages,
});

// stat panel dashboard
export const sGetTotalCriticalSpares = (store: IStore) =>
  store.dashboardReducer.criticalSpares?.data?.totalCount || 0;
export const sGetTotalPosPendingDelivery = (store: IStore) =>
  store.dashboardReducer.pendingDelivery?.data?.totalCount || 0;
export const sGetTotalPartsRestock = (store: IStore) =>
  (store.inventoryReducer.inventoryStatistics?.find(
    (o) => o.key === InventoryStatisticsTypeEnum.POTENTIAL_PARTS,
  )?.value || 0) as number;

// Inventory Reducer

export const sGetInventoryEqms = (store: IStore) =>
  store.inventoryReducer.parentEquiqments ?? [];
export const sGetInventoryDownloading = (store: IStore) =>
  store.inventoryReducer.downloading;
export const sGetInventoryExpanData = (store: IStore) =>
  store.inventoryReducer.expandData;
export const sGetInventoryList = (store: IStore) =>
  store.inventoryReducer.inventoryList;
export const sGetInventoryRecordPerPage = (store: IStore) =>
  store.inventoryReducer.params.recordsPerpage;
export const sGetInventorySortField = (store: IStore) =>
  store.inventoryReducer.params.sortField;
export const sGetInventorySort = (store: IStore) =>
  store.inventoryReducer.params.sort;
export const sGetInventoryTagStatuses = (store: IStore) =>
  store.inventoryReducer.params.tagStatuses;
export const sGetInventoryFilterbyROB = (store: IStore) =>
  store.inventoryReducer.params.onlyWithRob || false;
export const sGetInventoryFilterByPotentialPartsRestock = (store: IStore) =>
  store.inventoryReducer.params.isForReStock || false;
export const sGetProductDetail = (store: IStore) =>
  store.inventoryReducer.partProfile?.productDetail;
export const sGetProductProfiles = (store: IStore) =>
  store.inventoryReducer.partProfile?.profiles?.data;
export const sGetProductProfilesParams = (store: IStore) =>
  store.inventoryReducer.partProfile?.profiles?.params ?? {};
export const sGetProductPartData = (store: IStore) =>
  store.inventoryReducer.partProfile?.partData;
export const sGetProductVesselDaysOnboard = (store: IStore) =>
  store.inventoryReducer.partProfile?.vesselDaysOnboard;
export const sGetProductPriceHistory = (store: IStore) =>
  store.inventoryReducer.partProfile?.priceHistory?.data;
export const sGetProductPriceHistoryParams = (store: IStore) =>
  store.inventoryReducer.partProfile?.priceHistory?.params;
export const sGetProductPriceHistoryPaginationInfo = (store: IStore) =>
  store.inventoryReducer.partProfile?.priceHistory?.paginationInfo;
export const sGetInventoryStatisticsData = (store: IStore) =>
  store.inventoryReducer.inventoryStatistics;
export const sGetInventorySpareName = (store: IStore) =>
  store.inventoryReducer.params.spareName;
export const sGetInventoryPartNumber = (store: IStore) =>
  store.inventoryReducer.params.partNumber;
export const sGetInventoryIsCritical = (store: IStore) =>
  store.inventoryReducer.params.isCritical || false;
export const sGetInventoryManufactures = (store: IStore) =>
  store.inventoryReducer.params.manufactures || [];
export const sGetInventoryLocations = (store: IStore) =>
  store.inventoryReducer.params.locations || [];
export const sGetInventoryParentEquiqmentIds = (store: IStore) =>
  store.inventoryReducer.params.parentEquipmentIds || [];
export const sGetInventoryStartOrderDate = (store: IStore) =>
  store.inventoryReducer.params.poOrderDateFrom || '';
export const sGetInventoryEndOrderDate = (store: IStore) =>
  store.inventoryReducer.params.poOrderDateTo || '';
export const sGetInventoryPartProfileExpandData = (store: IStore) =>
  store.inventoryReducer.partProfile?.profiles?.expandData;
export const sGetInventoryIsRobBelowMin = (store: IStore) =>
  store.inventoryReducer.params.isRobBelowMin || false;
export const sGetInventoryDaysOnBoardFrom = (store: IStore) =>
  store.inventoryReducer.params.daysOnBoardFrom || 0;
export const sGetInventoryDaysOnBoardTo = (store: IStore) =>
  store.inventoryReducer.params.daysOnBoardTo ?? DAYS_ON_BOARD_MAX_VALUE;

// Stock recon reducer
export const sGetStockReconScheduleList = (store: IStore) =>
  store.stockReconScheduleReducer.stockReconScheduleList;
export const sGetStockReconScheduleDownloading = (store: IStore) =>
  store.stockReconScheduleReducer.downloading;
export const sGetStockReconScheduleRecordPerPage = (store: IStore) =>
  store.stockReconScheduleReducer.recordsPerpage;
export const sGetStockReconScheduleSort = (store: IStore) =>
  store.stockReconScheduleReducer.sort;
export const sGetStockReconScheduleSortField = (store: IStore) =>
  store.stockReconScheduleReducer.sortField;
export const sGetStockReconScheduleReadList = (store: IStore) =>
  store.stockReconScheduleReducer.readList;
export const sGetStockReconScheduleSelectedVessels = (store: IStore) =>
  store.stockReconScheduleReducer.selectedVessels;
export const sGetStockReconScheduleStartDate = (store: IStore) =>
  store.stockReconScheduleReducer.stockReconStartDate;
export const sGetStockReconScheduleEndDate = (store: IStore) =>
  store.stockReconScheduleReducer.stockReconEndDate;
export const sGetStockReconActivitiesSummary = (store: IStore) =>
  store.stockReconReducer.activitiesSummary;
export const sGetStockReconActivitiesPerDay = (store: IStore) =>
  store.stockReconReducer.activitiesPerDay;
export const sGetStockReconActivitiesPerDayByScheduleId = (
  activitySummaryId: string,
) => (store: IStore) =>
  store.stockReconReducer.activitiesPerDay.get(`${activitySummaryId}`);

// System health
export const sGetLastKnownConnectionDate = (store: IStore) =>
  store.systemHealthReducer.lastKnownConnectionDate;

// System info
export const sGetLatestActivityDate = (store: IStore) =>
  store.systemInfoReducer.latestDateCreated;

// PO
export const sGetPOBySpare = (store: IStore) =>
  store.purchaseOrderReducer.POBySpare;
// Activity log
export const sGetTransformationInfo = (store: IStore) =>
  store.activityLogReducer.transformationInfo;
export const sGetVesselsTransformation = (store: IStore) =>
  store.activityLogReducer.vesselsTransformation;

// Version
export const sGetVersionList = (store: IStore) =>
  store.versionReducer.versionList?.data;
export const sGetVersionLastest = (store: IStore) =>
  store.versionReducer.versionList?.data?.[0]?.version;
export const sGetVersionPagination = (store: IStore) => ({
  pageNumber: store.versionReducer.versionList?.pageNumber,
  recordsPerpage: store.versionReducer.versionList?.recordsPerpage,
  totalPages: store.versionReducer.versionList?.totalPages,
});

// Transformation Reducer
export const sGetTransformationParams = (store: IStore) =>
  store.transformationReducer.params;
export const sGetTransformationList = (store: IStore) =>
  store.transformationReducer.transformationList;
export const sGetTimeSpentTransformation = (store: IStore) =>
  store.transformationReducer.timeSpent;
export const sGetSpareSortOptionTransformation = (store: IStore) =>
  store.transformationReducer.spareSortOption;
