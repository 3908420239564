import axios from 'axios';
import { Vessel } from 'types/loginStore';
import { apiRoutesEnum, oxygenRoutesEnum } from '../enums/routes';
import {
  UserCredentials,
  UserEmailCredentials,
  ValidateResetTokenParams,
  ValidateResetTokenResponse,
} from '../types/user';
import axiosInstance from './axios';

const login = (data: UserCredentials) => {
  return axios.post(
    `${process.env.REACT_APP_BE_URL}${oxygenRoutesEnum.AUTH_LOGIN}`,
    {
      ...data,
    },
  );
};

async function resetPassword(data: UserEmailCredentials): Promise<void> {
  return axios.post(
    `${process.env.REACT_APP_BE_URL}${oxygenRoutesEnum.AUTH_RESET_PASSWORD}`,
    {
      ...data,
    },
  );
}

async function resetPasswordConfirm(
  resetToken: string,
  password: string,
): Promise<void> {
  return axios.post(
    `${process.env.REACT_APP_BE_URL}${oxygenRoutesEnum.AUTH_RESET_PASSWORD_CONFIRM}`,
    {
      resetToken,
      password,
    },
  );
}

const getUser = () => {
  return axiosInstance.get(apiRoutesEnum.USER_PROFILE);
};

const getVessels = (): Promise<Vessel[]> => {
  return axiosInstance.get(apiRoutesEnum.USER_VESSELS).then((vessels: any) =>
    vessels.map((vessel: any) => ({
      id: vessel.imoNo,
      displayName: vessel.vesselName,
      vesselClass: vessel.vesselClass,
    })),
  );
};

const validateResetToken = (
  params: ValidateResetTokenParams,
): Promise<ValidateResetTokenResponse> => {
  return axiosInstance.get(oxygenRoutesEnum.AUTH_VALIDATE_RESET_PASSWORD, {
    params,
  });
};

export default {
  login,
  getUser,
  resetPassword,
  resetPasswordConfirm,
  getVessels,
  validateResetToken,
};
