import { Map } from 'immutable';
import { defaultParams } from 'utils/defaultValues/transformation';
import { TransformationSortFieldEnum } from 'enums/transformation';
import { SortEnum } from 'enums/sortEnum';
import { TransformationActionTypeEnum } from '../../enums/actions';

import {
  TransformationActionTypes,
  TransformationReducerState,
} from '../../types/transformationStore';

export const initialState: TransformationReducerState = {
  transformationList: undefined,
  params: {
    recordsPerpage: defaultParams.recordsPerpage,
    pageNumber: defaultParams.pageNumber,
    sort: defaultParams.sort,
    sortField: defaultParams.sortField,
    vesselName: defaultParams.vesselName,
    startDate: defaultParams.startDate,
    endDate: defaultParams.endDate,
    status: defaultParams.status,
  },
  spareSortOption: {
    sort: SortEnum.DESCENDING,
    sortField: TransformationSortFieldEnum.ErpRob,
  },
  timeSpent: Map(),
};

export const transformationReducer = (
  state = initialState,
  action: TransformationActionTypes,
): TransformationReducerState => {
  switch (action.type) {
    case TransformationActionTypeEnum.READ_TRANSFORMATION_REQUEST:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case TransformationActionTypeEnum.READ_TRANSFORMATION_SUCCESS:
      return {
        ...state,
        transformationList: action.payload,
      };
    case TransformationActionTypeEnum.READ_TIME_SPENT_SUCCESS:
      return {
        ...state,
        timeSpent: state.timeSpent.set(
          `${action.meta.transformationId}`,
          action.payload.total,
        ),
      };
    case TransformationActionTypeEnum.UPDATE_SORT_FOR_SPARE:
      return {
        ...state,
        spareSortOption: action.payload,
      };
    default:
      return state;
  }
};
