export enum SecureStorageEnum {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  VESSEL_ID = 'vessel_id',
  USER_INFO = 'user_info',
}

export enum CopyrightEnum {
  COPYRIGHT_ICON = '© ',
}

export enum SparesEnum {
  SPARES_URL = 'https://www.sparescnx.com/',
}

export enum SetupPasswordFormTypeEnum {
  SETUP_PASSWORD = 'setup-password',
  RESET_PASSWORD = 'reset-password',
}

export enum AuthErrorCodeEnum {
  NoLoginPermission = '1000',
}

export enum ResetPasswordMsgEnum {
  ExpiredResetLinkMsg = 'jwt expired',
}
