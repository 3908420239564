import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  AnyAction,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  initialState as initialTaskState,
  asyncTaskReducer,
} from './reducers/asyncTaskReducer';

import {
  loginReducer,
  initialState as initialLoginState,
} from './reducers/loginReducer';
import {
  snackBarReducer,
  initialState as initialSnackBarState,
} from './reducers/snackBarReducer';
import {
  purchaseOrderReducer,
  initialState as initialPurchaseOrderState,
} from './reducers/purchaseOrderReducer';
import {
  inventoryReducer,
  initialState as initialInventoryState,
} from './reducers/inventoryReducer';
import {
  stockReconScheduleReducer,
  initialState as initialStockReconScheduleState,
} from './reducers/stockReconScheduleReducer';
import {
  activityLogReducer,
  initialState as initialActivityLogState,
} from './reducers/activityLogReducer';
import {
  stockReconReducer,
  initialState as initialStockReconState,
} from './reducers/stockReconReducer';
import {
  userAnalyticsReducer,
  initialState as initialUserAnalyticsState,
} from './reducers/userAnalyticsReducer';
import {
  systemHealthReducer,
  initialState as initialSystemHealthState,
} from './reducers/systemHealthReducer';
import {
  dashboardReducer,
  initialState as initialDashboardState,
} from './reducers/dashboardReducer';
import {
  systemInfoReducer,
  initialState as initialSystemInfoState,
} from './reducers/systemInfoReducer';
import {
  versionReducer,
  initialState as initialVersionState,
} from './reducers/versionReducer';
import {
  transformationReducer,
  initialState as initialTransformationState,
} from './reducers/transformationReducer';

import { RootStateType } from '../types/store';
import { LoginActionTypeEnum } from '../enums/actions';

export const initialRootState: RootStateType = {
  loginReducer: initialLoginState,
  snackBarReducer: initialSnackBarState,
  purchaseOrderReducer: initialPurchaseOrderState,
  inventoryReducer: initialInventoryState,
  stockReconScheduleReducer: initialStockReconScheduleState,
  activityLogReducer: initialActivityLogState,
  stockReconReducer: initialStockReconState,
  userAnalyticsReducer: initialUserAnalyticsState,
  systemHealthReducer: initialSystemHealthState,
  dashboardReducer: initialDashboardState,
  systemInfoReducer: initialSystemInfoState,
  versionReducer: initialVersionState,
  transformationReducer: initialTransformationState,
  asyncTaskReducer: initialTaskState,
};

export default function configureStore(
  preloadedState: RootStateType = initialRootState,
) {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(...enhancers)
      : compose(...enhancers);

  const appReducer = combineReducers<RootStateType>({
    loginReducer,
    snackBarReducer,
    inventoryReducer,
    stockReconScheduleReducer,
    purchaseOrderReducer,
    activityLogReducer,
    stockReconReducer,
    userAnalyticsReducer,
    systemHealthReducer,
    dashboardReducer,
    systemInfoReducer,
    versionReducer,
    transformationReducer,
    asyncTaskReducer,
  });

  // Reset state after logout
  const rootReducer = (state: RootStateType, action: AnyAction) => {
    return action.type === LoginActionTypeEnum.LOGOUT
      ? initialRootState
      : appReducer(state, action);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return createStore(rootReducer, preloadedState, composedEnhancers);
}
