import { SortEnum } from 'enums/sortEnum';
import { StockReconScheduleFieldEnum } from 'enums/stockReconSchedule';

export const defaultParams = {
  selectedVessels: [],
  stockReconStartDate: undefined,
  stockReconEndDate: undefined,
  sort: SortEnum.DESCENDING,
  sortField: StockReconScheduleFieldEnum.END_DATE,
};

export const HeaderProgressDefault = [
  {
    label: '# Finished',
    field: StockReconScheduleFieldEnum.EQUIPMENT_FINISHED,
  },
  {
    label: '# In Progress',
    field: StockReconScheduleFieldEnum.EQUIPMENT_IN_PROGRESS,
  },
  {
    label: '# Not Started',
    field: StockReconScheduleFieldEnum.EQUIPMENT_NOT_STARTED,
  },
];
