import { Map } from 'immutable';
import { isUndefined } from 'lodash';
import { StockReconActionTypeEnum } from '../../enums/actions';
import {
  StockReconActionTypes,
  StockReconReducerState,
} from '../../types/stockReconStore';
import { StockReconFilterFieldTypeEnum } from '../../enums/stockRecon';

export const initialState: StockReconReducerState = {
  filter: [StockReconFilterFieldTypeEnum.COUNT],
  activitiesPerDay: Map(),
  activitiesSummary: undefined,
  expandData: Map(),
  expandHistoryData: Map(),
};

export const stockReconReducer = (
  state = initialState,
  action: StockReconActionTypes,
): StockReconReducerState => {
  switch (action.type) {
    case StockReconActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        expandData: state.expandData.set(
          isUndefined(action.payload.criterias)
            ? null
            : action.payload.criterias?.equipmentId,
          'loading',
        ),
      };
    case StockReconActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        ...action.meta,
        expandData: state.expandData.set(
          isUndefined(action.meta.criterias)
            ? ''
            : action.meta.criterias.equipmentId,
          action.payload,
        ),
      };
    case StockReconActionTypeEnum.READ_LIST_STOCK_TAKE_HISTORY_REQUEST:
      if (state.expandHistoryData.get(action.payload.dataId) === 'loading') {
        return state;
      }
      return {
        ...state,
        expandHistoryData: state.expandHistoryData.set(
          action.payload.dataId,
          'loading',
        ),
      };
    case StockReconActionTypeEnum.READ_LIST_STOCK_TAKE_HISTORY_SUCCESS:
      return {
        ...state,
        expandHistoryData: state.expandHistoryData.set(
          action.meta.dataId,
          action.payload,
        ),
      };
    case StockReconActionTypeEnum.READ_LIST_STOCK_TAKE_HISTORY_FAIL:
      return {
        ...state,
        expandHistoryData: state.expandHistoryData.set(
          action.meta.dataId,
          'error',
        ),
      };
    case StockReconActionTypeEnum.READ_ACTIVITIES_PER_DAY: {
      return {
        ...state,
        activitiesPerDay: state.activitiesPerDay.set(
          `${action.meta.activitySummaryId}`,
          action.payload,
        ),
      };
    }
    case StockReconActionTypeEnum.READ_ACTIVITIES_SUMMARY: {
      return {
        ...state,
        activitiesSummary: action.payload,
      };
    }
    case StockReconActionTypeEnum.CHANGE_ACTIVITY_TYPE:
      return {
        ...state,
        filter: action.payload,
      };
    case StockReconActionTypeEnum.RESET:
      // reset avoid duplicate: expandData, expandHistoryData
      // but keep activitiesPerDay for reuse
      return {
        ...initialState,
        activitiesPerDay: state.activitiesPerDay,
      };
    default:
      return state;
  }
};
