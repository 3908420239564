export const DATA_TABLE_OPTIONS = {
  page: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 20, 30],
};

export const DATA_TABLE_OPTIONS_NO_PAGINATION = {
  page: 0,
  rowsPerPage: 1000,
  rowsPerPageOptions: [1000],
  pagination: false,
};
export const PAGE_VIEW_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const PURCHASE_ORDER_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const INVENTORY_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const STOCK_RECON_SCHEDULE_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const ACTIVITY_LOG_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const TRANSFORMATION_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const STOCK_RECON_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const SEARCH_VIEW_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
};

export const EMPTY_COLUMN = {
  name: '',
  label: '',
};
