import { ContentTypeEnum } from 'enums/exportDataEnum';

export type Base64 = string;

interface SaveFileParams {
  data: Base64;
  contentType: ContentTypeEnum;
  fileName: string;
}
// refer: https://stackoverflow.com/a/16245768/12280838
const base64ToBlob = (
  b64Data: Base64,
  contentType: string,
  sliceSize = 512,
): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const saveFile = ({ data, contentType, fileName }: SaveFileParams) => {
  if (typeof URL.createObjectURL === 'undefined') {
    // The method is not supported in very old versions of major browsers
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
    throw new Error('Browser is not supported');
  }
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    URL.createObjectURL(base64ToBlob(data, contentType)),
  );
  link.setAttribute('download', fileName);
  link.click();
};

export default {
  saveFile,
};
