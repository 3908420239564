import {
  CategoryOverview,
  ReadListTransformationParamsStatusEnum,
} from 'enums/transformation';
import { TRANSFORMATION_LIST_DATA_TABLE_OPTIONS } from './dataTable';

// return transformation status selected
export const TransformationStatus = [
  { label: ReadListTransformationParamsStatusEnum.COMPLETED, color: '#00ACAD' },
  {
    label: ReadListTransformationParamsStatusEnum.INPROGRESS,
    color: '#FFA401',
  },
  {
    label: ReadListTransformationParamsStatusEnum.NOT_STARTED,
    color: '#999999',
  },
];

export const TransformationStatusColor: {
  [key: string]: string;
} = TransformationStatus.reduce(
  (obj, currentVal) => ({ ...obj, [currentVal.label]: currentVal.color }),
  {},
);

export const defaultParams = {
  recordsPerpage: TRANSFORMATION_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
  pageNumber: TRANSFORMATION_LIST_DATA_TABLE_OPTIONS.page + 1,
  sort: undefined,
  sortField: undefined,
  vesselName: undefined,
  startDate: undefined,
  endDate: undefined,
  minDays: undefined,
  maxDays: undefined,
  status: undefined,
};

export const ErrorTypeDaysDurationMsg = 'Day must be integer number';
export const ErrorMinLengthDaysDurationMsg = 'Day must be more than 0';

// return category inventory overview
export const CategoryInventoryOverview = [
  { value: CategoryOverview.NUMBER_OF_EQUIPMENT, label: 'Number of Equipment' },
  {
    value: CategoryOverview.PARTS_QUANTITY,
    label: 'Parts Quantity',
  },
  {
    value: CategoryOverview.TOTAL_ROB,
    label: 'Total ROB',
  },
];
