import { DAYS_ON_BOARD_MAX_VALUE } from 'constants/ui';
import { SortEnum } from '../../enums/sortEnum';
import {
  InventoryFieldEnum,
  IncludeTagsEnum,
  InventoryTagFieldEnum,
} from '../../enums/inventory';

export const defaultParams = {
  sort: SortEnum.DESCENDING,
  sortField: InventoryFieldEnum.NAME,
  onlyWithRob: false,
  isCritical: false,
  spareName: undefined,
  partNumber: undefined,
  manufactures: [],
  parentEquipmentIds: [],
  locations: undefined,
  daysOnBoardFrom: 0,
  poOrderDateFrom: undefined,
  poOrderDateTo: undefined,
  daysOnBoardTo: DAYS_ON_BOARD_MAX_VALUE,
  tagStatuses: undefined,
  isRobBelowMin: false,
  isForReStock: false,
};

export const downloadTagOptions = [
  {
    label: 'Yes, include associated tag data',
    value: IncludeTagsEnum.YES,
  },
  {
    label: 'No, do not include associated tag data',
    value: IncludeTagsEnum.NO,
  },
];

export const exportTagFields = [
  {
    key: InventoryTagFieldEnum.ITEM_ORDER,
    label: 'Tag',
  },
  {
    key: InventoryTagFieldEnum.TAG_ID,
    label: 'Tag ID',
  },
  {
    key: InventoryTagFieldEnum.LOCATION,
    label: 'Location',
  },
  {
    key: InventoryTagFieldEnum.QTY,
    label: 'Quantity',
  },
  {
    key: InventoryTagFieldEnum.TAG_STATUS,
    label: 'Status',
  },
  {
    key: InventoryTagFieldEnum.DAYS_ON_BOARD,
    label: 'Days Onboard',
  },
];
