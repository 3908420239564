import moment from 'moment';
import { GetTagStatParams, GetTagUsedParams } from 'types/dashboard';
import { CalendarUnitEnum } from 'enums/dashboard';
import dateUtils from 'utils/date';
import { PREVIOUS_YEARS_LIMIT } from './date';

export const defatulTagUsageByDateRangeParams: GetTagUsedParams = {
  fromDate: dateUtils.getXDayFromDate(30).toISOString(),
  toDate: moment().endOf('day').toISOString(),
  imoNumber: null,
  dateInterval: CalendarUnitEnum.Day,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const defaultTagSummaryParams: GetTagStatParams = {
  fromDate: dateUtils.getXDayFromDate(30).toISOString(),
  toDate: moment().endOf('day').toISOString(),
};

export const defaultTagAllTimeParams: GetTagStatParams = {
  fromDate: dateUtils
    .getFirstDayOfPastNumberYear(PREVIOUS_YEARS_LIMIT)
    .toISOString(),
  toDate: moment().endOf('day').toISOString(),
};
