import React from 'react';
import ReactDOM from 'react-dom';
import 'moment-duration-format';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactGA from 'react-ga';

import ErrorFallback from './components/Error/ErrorFallback';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import configureStore from './store';
import { theme } from './styles/theme';

const store = configureStore();

ReactGA.initialize(process.env.REACT_APP_GA_APP_ID || '', {
  debug: process.env.NODE_ENV !== 'production',
});

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
