import { Map } from 'immutable';
import produce from 'immer';
import { InventoryExpandDataEnum } from 'enums/inventory';
import {
  DashboardActionTypeEnum,
  InventoryActionTypeEnum,
} from '../../enums/actions';
import { ReadCriticalSpareSuccessAction } from '../../types/dashboardStore';
import { InventoryType } from '../../types/inventory';
import {
  InventoryActionTypes,
  InventoryReducerState,
} from '../../types/inventoryStore';
import { INVENTORY_LIST_DATA_TABLE_OPTIONS } from '../../utils/defaultValues/dataTable';
import { defaultParams } from '../../utils/defaultValues/inventory';

export const initialState: InventoryReducerState = {
  error: null,
  inventoryList: undefined,
  inventoryMap: Map<string, InventoryType>(),
  downloading: false,
  expandData: Map(),
  partProfile: {},
  parentEquiqments: undefined,
  inventoryStatistics: undefined,
  params: {
    recordsPerpage: INVENTORY_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
    sort: defaultParams.sort,
    sortField: defaultParams.sortField,
    onlyWithRob: defaultParams.onlyWithRob,
    daysOnBoardFrom: defaultParams.daysOnBoardFrom,
    daysOnBoardTo: defaultParams.daysOnBoardTo,
    poOrderDateFrom: defaultParams.poOrderDateFrom,
    poOrderDateTo: defaultParams.poOrderDateTo,
  },
};

export const inventoryReducer = (
  state = initialState,
  action: InventoryActionTypes | ReadCriticalSpareSuccessAction,
): InventoryReducerState => {
  switch (action.type) {
    case InventoryActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case InventoryActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        inventoryList: {
          ...action.payload,
          totalCount:
            action.payload.pageNumber === 1
              ? action.payload.totalCount
              : state.inventoryList?.totalCount || 0,
        },
      };
    case InventoryActionTypeEnum.READ_LIST_TAG_REQUEST:
      if (state.expandData.get(action.payload.productId) === 'loading') {
        return state;
      }
      return {
        ...state,
        expandData: state.expandData.set(
          action.payload.productId,
          InventoryExpandDataEnum.LOADING,
        ),
      };
    case InventoryActionTypeEnum.READ_LIST_TAG_SUCCESS:
      return {
        ...state,
        expandData: state.expandData.set(action.meta.productId, action.payload),
      };
    case InventoryActionTypeEnum.READ_LIST_TAG_FAIL:
      return {
        ...state,
        expandData: state.expandData.set(
          action.meta.productId,
          InventoryExpandDataEnum.ERROR,
        ),
      };
    case InventoryActionTypeEnum.DOWNLOAD_DATA_REQUEST:
      return {
        ...state,
        downloading: true,
      };
    case InventoryActionTypeEnum.DOWNLOAD_DATA_SUCCESS:
    case InventoryActionTypeEnum.DOWNLOAD_DATA_FAIL:
      return {
        ...state,
        downloading: false,
      };
    case InventoryActionTypeEnum.READ_PART_PROFILES_REQUEST:
      return {
        ...state,
        partProfile: {
          ...state.partProfile,
          profiles: {
            ...state.partProfile.profiles,
            params: action.payload,
          },
        },
      };
    case InventoryActionTypeEnum.READ_PART_PROFILES_SUCCESS:
      return {
        ...state,
        partProfile: {
          ...state.partProfile,
          profiles: {
            ...state.partProfile.profiles,
            data: action.payload,
          },
        },
      };
    case InventoryActionTypeEnum.READ_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        partProfile: {
          ...state.partProfile,
          productDetail: action.payload,
        },
      };
    case InventoryActionTypeEnum.READ_PRODUCT_VESSEL_DAYS_ON_BOARD_SUCCESS:
      return {
        ...state,
        partProfile: {
          ...state.partProfile,
          vesselDaysOnboard: action.payload,
        },
      };
    case InventoryActionTypeEnum.READ_PRODUCT_SUP_EQM_SUCCESS:
      return {
        ...state,
        partProfile: {
          ...state.partProfile,
          partData: action.payload,
        },
      };
    case InventoryActionTypeEnum.READ_INVENTORY_STATISTICS_SUCCESS:
      return {
        ...state,
        inventoryStatistics: action.payload,
      };
    case InventoryActionTypeEnum.READ_PRODUCT_HIERARCHY_SUCCESS:
      return {
        ...state,
        parentEquiqments: action.payload,
      };
    case DashboardActionTypeEnum.READ_CRITICAL_SPARE_SUCCESS:
      return {
        ...state,
        inventoryMap: state.inventoryMap.withMutations((map) => {
          (action.payload.data || []).forEach((inventory) => {
            map.set(inventory.id, inventory);
          });
        }),
      };
    case InventoryActionTypeEnum.READ_PRICE_HISTORY_REQUEST:
      return {
        ...state,
        partProfile: {
          ...state.partProfile,
          priceHistory: {
            ...state.partProfile.priceHistory,
            params: action.payload,
          },
        },
      };
    case InventoryActionTypeEnum.READ_PRICE_HISTORY_SUCCESS:
      return {
        ...state,
        partProfile: {
          ...state.partProfile,
          priceHistory: {
            ...state.partProfile.priceHistory,
            data: action.payload.data,
            paginationInfo: {
              totalCount: action.payload.totalCount,
              totalPages: action.payload.totalPages,
            },
          },
        },
      };
    case InventoryActionTypeEnum.READ_LIST_TAG_PART_PROFILE_REQUEST: {
      let partProfileRequestExpandData =
        state.partProfile?.profiles?.expandData || {};
      partProfileRequestExpandData = {
        ...partProfileRequestExpandData,
        [action.payload.productId]: {
          [action.payload.imoNumber]: InventoryExpandDataEnum.LOADING,
        },
      };
      return produce(state, (draftState) => {
        draftState.partProfile.profiles = draftState.partProfile.profiles || {};
        draftState.partProfile.profiles.expandData = partProfileRequestExpandData;
      });
    }
    case InventoryActionTypeEnum.READ_LIST_TAG_PART_PROFILE_SUCCESS: {
      let partProfileSuccessExpandData =
        state.partProfile?.profiles?.expandData || {};
      partProfileSuccessExpandData = {
        ...partProfileSuccessExpandData,
        [action.meta.productId]: {
          [action.meta.imoNumber]: action.payload,
        },
      };
      return produce(state, (draftState) => {
        draftState.partProfile.profiles = draftState.partProfile.profiles || {};
        draftState.partProfile.profiles.expandData = partProfileSuccessExpandData;
      });
    }
    case InventoryActionTypeEnum.READ_LIST_TAG_PART_PROFILE_FAIL: {
      let partProfileFailExpandData =
        state.partProfile?.profiles?.expandData || {};
      partProfileFailExpandData = {
        ...partProfileFailExpandData,
        [action.meta.productId]: {
          [action.meta.imoNumber]: InventoryExpandDataEnum.ERROR,
        },
      };
      return produce(state, (draftState) => {
        draftState.partProfile.profiles = draftState.partProfile.profiles || {};
        draftState.partProfile.profiles.expandData = partProfileFailExpandData;
      });
    }
    case InventoryActionTypeEnum.CHANGE_DAYS_ON_BOARD_PARAMS: {
      return {
        ...state,
        params: {
          ...state.params,
          daysOnBoardFrom: action.payload[0],
          daysOnBoardTo: action.payload[1],
        },
      };
    }
    default:
      return state;
  }
};
