import { ExportFileNameEnum } from 'enums/exportFileName';
import { ContentTypeEnum } from 'enums/exportDataEnum';
import actionUtils from 'utils/action';
import { Vessel } from 'types/loginStore';
import moment from 'moment';
import { SecureStorageEnum } from 'enums/auth';
import some from 'lodash/some';
import secureStorageUtils from '../../utils/secureStorage';
import fileUtils, { Base64 } from '../../utils/file';
import { updateSnackBarAction } from './snackBarActions';
import activityLogService from '../../services/ActivityLog';
import { DispatchType, ThunkActionType } from '../../types/store';
import {
  ReadListActivityLogParams,
  ReadListActivityLogResponse,
  DownloadActivityLogParams,
  TransformationInfo,
  VesselsTransformation,
} from '../../types/activityLog';
import { ApiError } from '../../types/api';
import {
  ReadListActivityLogSuccessAction,
  ReadListActivityLogRequestAction,
  DownloadActivityLogFailAction,
  DownloadActivityLogSuccessAction,
  DownloadActivityLogRequestAction,
  ToggleQuantityAction,
  ReadTransformationInfoSuccessAction,
} from '../../types/activityLogStore';
import { ActivityLogActionTypeEnum } from '../../enums/actions';
import {
  ACTIVITY_LOG_READ_LIST_FAIL_NOTIFICATION,
  ACTIVITY_LOG_DOWNLOAD_DATA_STARTING_NOTIFICATION,
  ACTIVITY_LOG_DOWNLOAD_DATA_FAIL_NOTIFICATION,
  TRANSFORMATION_INFO_FAIL,
} from '../../utils/defaultValues/snackBar';
import { ReadListActivityLogParamsTypeEnum } from '../../enums/activityLog';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';
import { logoutAction } from './loginActions';

export const toggleDisplayQuantityAction = (
  value: boolean,
): ToggleQuantityAction => ({
  type: ActivityLogActionTypeEnum.TOGGLE_DISPLAY_QUANTITY,
  payload: value,
});

export const readListActivityLogRequestAction = (
  args?: Partial<ReadListActivityLogParams>,
): ReadListActivityLogRequestAction => ({
  type: ActivityLogActionTypeEnum.READ_LIST_REQUEST,
  payload: args,
});

export const readListActivityLogSuccessAction = (
  payload: ReadListActivityLogResponse,
  params?: Partial<ReadListActivityLogParams>,
): ReadListActivityLogSuccessAction => ({
  type: ActivityLogActionTypeEnum.READ_LIST_SUCCESS,
  payload,
  meta: params,
});

export const readListActivityLogFailAction = (payload: ApiError) => ({
  type: ActivityLogActionTypeEnum.READ_LIST_FAIL,
  payload,
});

export function readListActivityLogAction(
  args?: Partial<ReadListActivityLogParams>,
): ThunkActionType {
  return async (dispatch: DispatchType, getState): Promise<void> => {
    try {
      dispatch(readListActivityLogRequestAction(args));

      // Get current filter options from state
      const { activityLogReducer } = getState();
      const {
        filter,
        recordsPerpage,
        startTime,
        endTime,
        spareName,
        sparePartNumber,
        reason,
        tagLabel,
        includeStat,
        sort,
        sortField,
        reasonFields,
      } = activityLogReducer;

      // Using default params from current state if omit
      const params = {
        pageNumber: args?.pageNumber || 1,
        recordsPerpage: args?.recordsPerpage || recordsPerpage,
        filter: args?.filter || filter,
        startTime: args?.startTime || startTime,
        endTime: args?.endTime || endTime,
        includeStat: args?.includeStat || includeStat,
        sort: args?.sort || sort,
        sortField: args?.sortField || sortField,
        spareName: args?.spareName || spareName,
        sparePartNumber: args?.sparePartNumber || sparePartNumber,
        reason: args?.reason || reason,
        tagLabel: (args?.tagLabel || tagLabel)?.replace(/\s/g, ''),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        reasonFields: args?.reasonFields || reasonFields,
      };
      if (params.startTime) {
        params.startTime = new Date(
          new Date(params.startTime).setHours(0, 0, 0, 0),
        ).toISOString();
      }
      if (params.endTime) {
        params.endTime = new Date(
          new Date(params.endTime).setHours(23, 59, 59, 999),
        ).toISOString();
      }

      // No filter specified means all selected
      if (!params.filter || !params.filter.length) {
        params.filter = Object.values(ReadListActivityLogParamsTypeEnum);
      }

      const payload = await activityLogService.readListActivityLog(params);
      dispatch(readListActivityLogSuccessAction(payload, args));
    } catch (error) {
      dispatch(readListActivityLogFailAction(error));
      dispatch(
        updateSnackBarAction(ACTIVITY_LOG_READ_LIST_FAIL_NOTIFICATION, error),
      );
    }
  };
}

// Download
export const downloadDataRequestAction = (
  params?: Partial<DownloadActivityLogParams>,
): DownloadActivityLogRequestAction => ({
  type: ActivityLogActionTypeEnum.DOWNLOAD_DATA_REQUEST,
  payload: params,
});

export const downloadDataSuccessAction = (
  payload: Base64,
  params?: Partial<DownloadActivityLogParams>,
): DownloadActivityLogSuccessAction => ({
  type: ActivityLogActionTypeEnum.DOWNLOAD_DATA_SUCCESS,
  payload,
  meta: params,
});

export const downloadDataFailAction = (
  payload: ApiError,
): DownloadActivityLogFailAction => ({
  type: ActivityLogActionTypeEnum.DOWNLOAD_DATA_FAIL,
  payload,
});

export function downloadDataAction(
  args?: DownloadActivityLogParams,
): ThunkActionType {
  return async (dispatch: DispatchType, getState): Promise<void> => {
    try {
      dispatch(downloadDataRequestAction(args));
      dispatch(
        updateSnackBarAction(ACTIVITY_LOG_DOWNLOAD_DATA_STARTING_NOTIFICATION),
      );

      // Get current filter options from state
      const { activityLogReducer } = getState();
      const {
        filter,
        startTime,
        endTime,
        includeStat,
        spareName,
        sparePartNumber,
        reason,
        tagLabel,
        sort,
        sortField,
        reasonFields,
      } = activityLogReducer;

      // Using default params from current state if omit
      const params = {
        filter: args?.filter || filter,
        startTime: args?.startTime || startTime,
        endTime: args?.endTime || endTime,
        includeStat: args?.includeStat || includeStat,
        exportFields: args?.exportFields || [],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sort: args?.sort || sort,
        sortField: args?.sortField || sortField,
        spareName: args?.spareName || spareName,
        sparePartNumber: args?.sparePartNumber || sparePartNumber,
        reason: args?.reason || reason,
        tagLabel: (args?.tagLabel || tagLabel)?.replace(/\s/g, ''),
        reasonFields: args?.reasonFields || reasonFields,
      };
      params.startTime = new Date(
        new Date(params.startTime).setHours(0, 0, 0, 0),
      ).toISOString();
      params.endTime = new Date(
        new Date(params.endTime).setHours(23, 59, 59, 999),
      ).toISOString();
      // No filter specified means all selected
      if (!params.filter || !params.filter.length) {
        params.filter = Object.values(ReadListActivityLogParamsTypeEnum);
      }

      const payload = await activityLogService.downloadData(params);
      fileUtils.saveFile({
        data: payload,
        contentType: ContentTypeEnum.Xlsx,
        fileName: ExportFileNameEnum.activity,
      });
      dispatch(downloadDataSuccessAction(payload, args));
    } catch (error) {
      dispatch(downloadDataFailAction(error));
      dispatch(
        updateSnackBarAction(
          ACTIVITY_LOG_DOWNLOAD_DATA_FAIL_NOTIFICATION,
          error,
        ),
      );
    }
  };
}

export const readTransformationInfoSuccessAction = (
  vesselsTransformation: VesselsTransformation,
  currentVesselTransformation?: TransformationInfo,
): ReadTransformationInfoSuccessAction => ({
  type: ActivityLogActionTypeEnum.READ_TRANSFORMAION_SUCCESS,
  vesselsTransformation,
  currentVesselTransformation,
});

export function readTransformationInfoAction(
  vessels: Vessel[],
): ThunkActionType {
  const taskId = ActivityLogActionTypeEnum.READ_TRANSFORMAION_SUCCESS;
  return async (dispatch: DispatchType): Promise<void> => {
    try {
      // Dispatch processing
      dispatch(asyncTaskStartAction(taskId));
      const transformed: TransformationInfo[] = [];
      const inProgress: TransformationInfo[] = [];
      const upcoming: TransformationInfo[] = [];
      const vesselsTransformation = new Map<string, any>();
      vessels.forEach((item) => vesselsTransformation.set(item.id, item));
      const imoNumbers = Array.from(vesselsTransformation.keys());
      const payload = await activityLogService.readTransformationByVessel(
        imoNumbers,
      );
      payload.forEach((item) =>
        vesselsTransformation.set(item.key, {
          ...vesselsTransformation.get(item.key),
          startDate: item.value.startDate,
          endDate: item.value.endDate,
        }),
      );

      // Filter vessel transformed, inProgress and upcoming
      Array.from(vesselsTransformation.values()).forEach((value) => {
        const { startDate, endDate } = value;
        if (endDate && moment(endDate).isBefore(new Date())) {
          transformed.push(value);
          return;
        }
        if (startDate) {
          if (moment(startDate).isAfter(new Date())) {
            upcoming.push(value);
          } else inProgress.push(value);
        } else {
          upcoming.push(value);
        }
      });
      const selectedVesselsLogin = [...transformed, ...inProgress, ...upcoming];

      const currentSelectedVessel = secureStorageUtils.getItem(
        SecureStorageEnum.VESSEL_ID,
      );
      // Save vessel_id if needed
      if (selectedVesselsLogin && selectedVesselsLogin.length) {
        if (
          !currentSelectedVessel ||
          upcoming.findIndex((val) => val.id === currentSelectedVessel) >= 0 ||
          selectedVesselsLogin.findIndex(
            (val) => val.id === currentSelectedVessel,
          ) === -1
        ) {
          secureStorageUtils.setItem(
            SecureStorageEnum.VESSEL_ID,
            selectedVesselsLogin[0].id as string,
          );
        }
      } else {
        // Delete the vessel_id in storage because it's invalid!
        secureStorageUtils.setItem(SecureStorageEnum.VESSEL_ID, '');
        dispatch(logoutAction());
        return;
      }
      const currentVesselId = actionUtils.getVesselId();
      const isTransformedVessel = some(transformed, { id: currentVesselId });
      let currentVesselTransformation = vesselsTransformation.get(
        currentVesselId,
      );
      currentVesselTransformation = {
        ...currentVesselTransformation,
        isTransformedVessel,
      };
      // return data to reducer
      dispatch(
        readTransformationInfoSuccessAction(
          {
            transformed,
            inProgress,
            upcoming,
          },
          currentVesselTransformation,
        ),
      );
      // Dispatch done
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error));
      dispatch(updateSnackBarAction(TRANSFORMATION_INFO_FAIL, error));
    }
  };
}
