import moment from 'moment';
import dateUtils from 'utils/date';
import { SystemHealthActionTypeEnum } from '../../enums/actions';
import {
  SystemHealthActionTypes,
  SystemHealthReducerState,
} from '../../types/systemHealthStore';

export const initialState: SystemHealthReducerState = {
  systemStatsStartTime: moment().subtract(7, 'days').toISOString(),
  systemStatsEndTime: moment().toISOString(),
  startTime: dateUtils.getXDayFromDate(30).toISOString(),
  endTime: moment().endOf('day').toISOString(),
  lastKnownConnectionDate: '',
  systemConnectivityRate: {
    fromDate: dateUtils.getXDayFromDate(30).toISOString(),
    toDate: moment().endOf('day').toISOString(),
  },
  systemUptime: {
    fromDate: dateUtils.getXDayFromDate(30).toISOString(),
    toDate: moment().endOf('day').toISOString(),
  },
  systemDataUsage: {
    fromDate: dateUtils.getXDayFromDate(30).toISOString(),
    toDate: moment().endOf('day').toISOString(),
  },
};

export const systemHealthReducer = (
  state = initialState,
  action: SystemHealthActionTypes,
): SystemHealthReducerState => {
  switch (action.type) {
    case SystemHealthActionTypeEnum.READ_CPU_STAT_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case SystemHealthActionTypeEnum.READ_CPU_STAT_SUCCESS:
      return {
        ...state,
        ...action.meta,
        cpuStat: action.payload,
      };

    case SystemHealthActionTypeEnum.READ_SYSTEM_STATS_SUCCESS:
      return {
        ...state,
        systemStats: action.payload,
      };

    case SystemHealthActionTypeEnum.READ_LAST_CONNECTTION_DATE_SUCCESS:
      return {
        ...state,
        lastKnownConnectionDate: action.payload.lastKnownConnectionDate,
      };

    case SystemHealthActionTypeEnum.READ_SYSTEM_CONNECTIVITY_RATE_REQUEST:
      return {
        ...state,
        systemConnectivityRate: {
          ...state.systemConnectivityRate,
          ...action.payload,
        },
      };
    case SystemHealthActionTypeEnum.READ_SYSTEM_CONNECTIVITY_RATE_SUCCESS:
      return {
        ...state,
        systemConnectivityRate: {
          ...state.systemConnectivityRate,
          ...action.meta,
          chartData: action.payload,
        },
      };

    case SystemHealthActionTypeEnum.READ_SYSTEM_UPTIME_REQUEST:
      return {
        ...state,
        systemUptime: {
          ...state.systemUptime,
          ...action.payload,
        },
      };
    case SystemHealthActionTypeEnum.READ_SYSTEM_UPTIME_SUCCESS:
      return {
        ...state,
        systemUptime: {
          ...state.systemUptime,
          ...action.meta,
          chartData: action.payload,
        },
      };

    case SystemHealthActionTypeEnum.READ_DATA_USAGE_REQUEST:
      return {
        ...state,
        systemDataUsage: {
          ...state.systemDataUsage,
          ...action.payload,
        },
      };
    case SystemHealthActionTypeEnum.READ_DATA_USAGE_SUCCESS:
      return {
        ...state,
        systemDataUsage: {
          ...state.systemDataUsage,
          ...action.meta,
          dataUsage: action.payload,
        },
      };

    default:
      return state;
  }
};
