/**
 * Default values for reducer's API request state
 */

import { ApiRequestState } from '../../types/api';

export const API_INITIAL_STATE: ApiRequestState = {
  apiRequest: false,
  apiSuccess: false,
  apiFail: false,
};

export const API_REQUEST_STATE: ApiRequestState = {
  apiRequest: true,
  apiSuccess: false,
  apiFail: false,
};

export const API_SUCCESS_STATE: ApiRequestState = {
  apiRequest: false,
  apiSuccess: true,
  apiFail: false,
};

export const API_FAIL_STATE: ApiRequestState = {
  apiRequest: false,
  apiSuccess: false,
  apiFail: true,
};
