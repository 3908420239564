import { ActivityLogActionTypeEnum } from '../../enums/actions';
import {
  ActivityLogActionTypes,
  ActivityLogReducerState,
} from '../../types/activityLogStore';
import {
  API_INITIAL_STATE,
  API_FAIL_STATE,
  API_REQUEST_STATE,
  API_SUCCESS_STATE,
} from '../../utils/defaultValues/apiRequestState';
import { PURCHASE_ORDER_LIST_DATA_TABLE_OPTIONS } from '../../utils/defaultValues/dataTable';
import { ActivityLogDefaultParams } from '../../utils/defaultValues/activityLog';

export const initialState: ActivityLogReducerState = {
  readList: API_INITIAL_STATE,
  error: null,
  activityLogList: undefined,
  recordsPerpage: PURCHASE_ORDER_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
  filter: ActivityLogDefaultParams.filter,
  readSource: ActivityLogDefaultParams.readSource,
  startTime: ActivityLogDefaultParams.startTime,
  endTime: ActivityLogDefaultParams.endTime,
  includeStat: ActivityLogDefaultParams.includeStat,
  downloading: false,
  showQuantity: ActivityLogDefaultParams.showQuantity,
  sort: ActivityLogDefaultParams.sort,
  sortField: ActivityLogDefaultParams.sortField,
};

export const activityLogReducer = (
  state = initialState,
  action: ActivityLogActionTypes,
): ActivityLogReducerState => {
  switch (action.type) {
    case ActivityLogActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        readList: API_REQUEST_STATE,
      };
    case ActivityLogActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        readList: API_SUCCESS_STATE,
        activityLogList: action.payload,
      };
    case ActivityLogActionTypeEnum.READ_LIST_FAIL:
      return {
        ...state,
        readList: API_FAIL_STATE,
        error: action.payload,
      };
    case ActivityLogActionTypeEnum.DOWNLOAD_DATA_REQUEST:
      return {
        ...state,
        downloading: true,
      };
    case ActivityLogActionTypeEnum.DOWNLOAD_DATA_SUCCESS:
    case ActivityLogActionTypeEnum.DOWNLOAD_DATA_FAIL:
      return {
        ...state,
        downloading: false,
      };
    case ActivityLogActionTypeEnum.TOGGLE_DISPLAY_QUANTITY:
      return {
        ...state,
        showQuantity: action.payload,
      };
    case ActivityLogActionTypeEnum.READ_TRANSFORMAION_SUCCESS:
      return {
        ...state,
        transformationInfo: action.currentVesselTransformation,
        vesselsTransformation: action.vesselsTransformation,
      };
    default:
      return state;
  }
};
