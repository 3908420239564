import { UserAnalyticsActionTypeEnum } from '../../enums/actions';
import {
  UserAnalyticsActionTypes,
  UserAnalyticsReducerState,
} from '../../types/userAnalyticsStore';
import {
  API_INITIAL_STATE,
  API_FAIL_STATE,
  API_REQUEST_STATE,
  API_SUCCESS_STATE,
} from '../../utils/defaultValues/apiRequestState';
import { AnalyticsDefaultParams } from '../../utils/defaultValues/userAnalytics';

export const initialState: UserAnalyticsReducerState = {
  requestState: API_INITIAL_STATE,
  error: null,
  startTime: AnalyticsDefaultParams.startTime,
  endTime: AnalyticsDefaultParams.endTime,
  downloadingPageView: false,
  downloadingSearchView: false,
};

export const userAnalyticsReducer = (
  state = initialState,
  action: UserAnalyticsActionTypes,
): UserAnalyticsReducerState => {
  switch (action.type) {
    case UserAnalyticsActionTypeEnum.READ_REQUEST:
      return {
        ...state,
        ...action.payload,
        requestState: API_REQUEST_STATE,
      };
    case UserAnalyticsActionTypeEnum.READ_SUCCESS:
      return {
        ...state,
        ...action.meta,
        requestState: API_SUCCESS_STATE,
        data: action.payload,
      };
    case UserAnalyticsActionTypeEnum.READ_FAIL:
      return {
        ...state,
        requestState: API_FAIL_STATE,
        error: action.payload,
      };
    case UserAnalyticsActionTypeEnum.DOWNLOAD_PAGE_VIEW_DATA_REQUEST:
      return {
        ...state,
        downloadingPageView: true,
      };
    case UserAnalyticsActionTypeEnum.DOWNLOAD_PAGE_VIEW_DATA_SUCCESS:
    case UserAnalyticsActionTypeEnum.DOWNLOAD_PAGE_VIEW_DATA_FAIL:
      return {
        ...state,
        downloadingPageView: false,
      };
    case UserAnalyticsActionTypeEnum.DOWNLOAD_SEARCH_VIEW_DATA_REQUEST:
      return {
        ...state,
        downloadingSearchView: true,
      };
    case UserAnalyticsActionTypeEnum.DOWNLOAD_SEARCH_VIEW_DATA_SUCCESS:
    case UserAnalyticsActionTypeEnum.DOWNLOAD_SEARCH_VIEW_DATA_FAIL:
      return {
        ...state,
        downloadingSearchView: false,
      };
    default:
      return state;
  }
};
