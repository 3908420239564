export enum ActivityLogFieldEnum {
  TIMESTAMP = 'time',
  PART_NAME = 'spareName',
  PART_NUMBER = 'sparePartNumber',
  ACTIVITY = 'type',
  REASON = 'reason',
  SOURCE = 'dataSource',
  QUANTITY = 'delta',
  TAG_ID = 'tagLabel',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum ReadListActivityLogParamsTypeEnum {
  CHECK_IN = 'Checked In',
  CHECK_OUT = 'Checked Out',
  TAGGED = 'Tagged',
  UPDATED_QUANTITY = 'Updated Quantity',
  UPDATED_LOCATION = 'Updated Location',
  REMOVED_TAG = 'Removed Tag',
}

export enum ReadSourceEnum {
  APP = 'APP',
  OFFLINE_APP = 'OFFLINE APP',
  DOOR = 'DOOR',
}

export enum FilterFormEnum {
  SPARE_NAME = 'spareName',
  PART_NUMBER = 'sparePartNumber',
  NOTES = 'reason',
  TAG_ID = 'tagLabel',
}

export enum ActivityLogNotesEnum {
  CHECKOUT_WITHOUT_WO_NUMBER = 'The WO number is waiting to sync from your ERP.',
}

export enum ActivityLogReasonValueEnum {
  Other = 'Other',
}
