export enum ExportFileNameEnum {
  activity = 'activity.xlsx',
  analyticsPage = 'analytics-page-stat.xlsx',
  analyticsSearch = 'analytics-search-stat.xlsx',
  purchaseOrder = 'purchase-order.xlsx',
  product = 'product.xlsx',
  stockTakeSchedule = 'stock-take-schedule.xlsx',
  transformation = 'transformation.xlsx',
  dataUsage = 'data-usage.xlsx',
}
