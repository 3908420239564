import React, { Suspense, lazy, useMemo, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { readTransformationInfoAction } from 'store/actions/activityLogActions';
import importRetry from 'utils/importRetry';
import CircularLoader from '../../components/Loader/CircularLoader';
import SnackBar from '../../components/SnackBar';
import secureStorageUtils from '../../utils/secureStorage';
import { SecureStorageEnum } from '../../enums/auth';
import {
  sAuthenticated,
  sGetVesselsTransformation,
  sVessels,
} from '../../store/selectors/selectors';
import { loadUserVessels } from '../../store/actions/loginActions';

const AppRoutes = lazy(() =>
  importRetry(() => import('../../components/Routes/AppRoutes')),
);
const AuthRoutes = lazy(() =>
  importRetry(() => import('../../components/Routes/AuthRoutes')),
);

/**
 * App container
 * Check user's authentication
 * Render routes based on user's authentication
 */
export default function App() {
  const dispatch = useDispatch();
  const isStoreAuthenticated = useSelector(sAuthenticated);
  const vessels = useSelector(sVessels);
  const token = secureStorageUtils.getItem(SecureStorageEnum.ACCESS_TOKEN);
  const isAuthenticated = token || isStoreAuthenticated;
  const vesselsTransformation = useSelector(sGetVesselsTransformation);

  useEffect(() => {
    // only fetch the profile when the token is changed
    if (token) {
      dispatch(loadUserVessels());
    } else {
      // Remove user profile if needed!
    }
  }, [token, dispatch]);

  useEffect(() => {
    // only fetch the transformation info when the vessels is changed
    if (vessels) {
      dispatch(readTransformationInfoAction(vessels));
    } else {
      // Remove vessel if needed!
    }
  }, [vessels, dispatch]);

  const AppComponent = useMemo(() => {
    // navigate to login page immediatelly!
    if (!isAuthenticated) {
      return <AuthRoutes />;
    }
    // waiting while the app is fetching user profile.
    if (vessels && vesselsTransformation) {
      return <AppRoutes />;
    }
    // Show the loader while the profile is being fetched.
    return <CircularLoader />;
  }, [isAuthenticated, vessels, vesselsTransformation]);

  return (
    <BrowserRouter>
      <Suspense fallback={<CircularLoader />}>
        {AppComponent}
        <SnackBar />
      </Suspense>
    </BrowserRouter>
  );
}
