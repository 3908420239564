import {
  defatulTagUsageByDateRangeParams,
  defaultTagAllTimeParams,
  defaultTagSummaryParams,
} from 'utils/defaultValues/dashboard';
import { DashboardActionTypeEnum } from '../../enums/actions';
import {
  DashboardActionTypes,
  DashboardReducerState,
} from '../../types/dashboardStore';
import {
  API_INITIAL_STATE,
  API_FAIL_STATE,
  API_REQUEST_STATE,
  API_SUCCESS_STATE,
} from '../../utils/defaultValues/apiRequestState';

export const initialState: DashboardReducerState = {
  tagsUsed: {
    data: undefined,
    imoNumber: defatulTagUsageByDateRangeParams.imoNumber,
    fromDate: defatulTagUsageByDateRangeParams.fromDate,
    toDate: defatulTagUsageByDateRangeParams.toDate,
    dateInterval: defatulTagUsageByDateRangeParams.dateInterval,
    timeZone: defatulTagUsageByDateRangeParams.timeZone,
  },
  criticalSpares: {
    request: API_INITIAL_STATE,
  },
  pendingDelivery: {
    request: API_INITIAL_STATE,
  },
  tagStat: {
    params: defaultTagSummaryParams,
  },
  tagAllTime: {
    params: defaultTagAllTimeParams,
  },
};

export const dashboardReducer = (
  state = initialState,
  action: DashboardActionTypes,
): DashboardReducerState => {
  switch (action.type) {
    case DashboardActionTypeEnum.READ_CRITICAL_SPARE_REQUEST:
      return {
        ...state,
        criticalSpares: {
          ...state.criticalSpares,
          error: undefined,
          request: API_REQUEST_STATE,
        },
      };
    case DashboardActionTypeEnum.READ_CRITICAL_SPARE_SUCCESS:
      return {
        ...state,
        criticalSpares: {
          ...state.criticalSpares,
          request: API_SUCCESS_STATE,
          // merge recordPerPage in data
          data: { ...action.meta, ...action.payload },
        },
      };
    case DashboardActionTypeEnum.READ_CRITICAL_SPARE_FAIL:
      return {
        ...state,
        criticalSpares: {
          ...state.criticalSpares,
          request: API_FAIL_STATE,
          error: action.payload,
        },
      };
    case DashboardActionTypeEnum.PD_REQUEST:
      return {
        ...state,
        pendingDelivery: {
          ...state.pendingDelivery,
          error: undefined,
          request: API_REQUEST_STATE,
        },
      };
    case DashboardActionTypeEnum.PD_SUCCESS:
      return {
        ...state,
        pendingDelivery: {
          ...state.pendingDelivery,
          request: API_SUCCESS_STATE,
          // merge recordPerPage in data
          data: { ...action.meta, ...action.payload },
        },
      };
    case DashboardActionTypeEnum.PD_FAIL:
      return {
        ...state,
        pendingDelivery: {
          ...state.pendingDelivery,
          request: API_FAIL_STATE,
          error: action.payload,
        },
      };
    case DashboardActionTypeEnum.TAGS_USED_REQUEST:
      return {
        ...state,
        tagsUsed: {
          ...state.tagsUsed,
          ...action.payload,
        },
      };
    case DashboardActionTypeEnum.TAGS_USED_SUCCESS:
      return {
        ...state,
        tagsUsed: {
          ...state.tagsUsed,
          data: action.payload,
          ...action.meta,
        },
      };
    case DashboardActionTypeEnum.TAG_STAT_REQUEST: {
      return {
        ...state,
        tagStat: {
          ...state.tagStat,
          params: { ...state.tagStat.params, ...action.payload },
        },
      };
    }
    case DashboardActionTypeEnum.TAG_STAT_SUCCESS:
      return {
        ...state,
        tagStat: {
          ...state.tagStat,
          data: action.payload,
          params: { ...state.tagStat.params, ...action.meta },
        },
      };
    case DashboardActionTypeEnum.TAG_ALL_TIME_SUCCESS:
      return {
        ...state,
        tagAllTime: {
          ...state.tagAllTime,
          data: action.payload,
          params: { ...state.tagAllTime.params },
        },
      };
    case DashboardActionTypeEnum.SPARE_COUNT_BY_STATUS_SUCCESS:
      return {
        ...state,
        spareCountByStatus: action.payload,
      };
    default:
      return state;
  }
};
