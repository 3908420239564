import { LoginActionTypeEnum } from '../../enums/actions';
import { LoginActionTypes, LoginReducerState } from '../../types/loginStore';

export const initialState: LoginReducerState = {
  status: false,
  loading: false,
  user: undefined,
  vessels: undefined,
};

export const loginReducer = (
  state = initialState,
  action: LoginActionTypes,
): LoginReducerState => {
  switch (action.type) {
    case LoginActionTypeEnum.SET_LOGIN:
      return {
        ...state,
        status: true,
      };
    case LoginActionTypeEnum.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LoginActionTypeEnum.SET_VESSELS:
      return {
        ...state,
        vessels: action.payload,
      };
    case LoginActionTypeEnum.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
