export enum StockReconScheduleFieldEnum {
  VESSEL_NAME = 'vesselName',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  EQUIPMENT_TO_RECON = 'totalEquipmentsToRecon',
  EQUIPMENT_FINISHED = 'totalEquipmentsFinished',
  EQUIPMENT_IN_PROGRESS = 'totalEquipmentsInProgress',
  EQUIPMENT_NOT_STARTED = 'totalEquipmentsNotStarted',
  TIME_SPENT = 'timeSpent',
}
