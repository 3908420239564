import { StockReconScheduleActionTypeEnum } from '../../enums/actions';
import { ReadCriticalSpareSuccessAction } from '../../types/dashboardStore';
import {
  StockReconScheduleActionTypes,
  StockReconScheduleReducerState,
} from '../../types/stockReconScheduleStore';
import {
  API_INITIAL_STATE,
  API_FAIL_STATE,
  API_REQUEST_STATE,
  API_SUCCESS_STATE,
} from '../../utils/defaultValues/apiRequestState';
import { STOCK_RECON_SCHEDULE_LIST_DATA_TABLE_OPTIONS } from '../../utils/defaultValues/dataTable';
import { defaultParams } from '../../utils/defaultValues/stockReconSchedule';

export const initialState: StockReconScheduleReducerState = {
  readList: API_INITIAL_STATE,
  error: null,
  stockReconScheduleList: undefined,
  recordsPerpage: STOCK_RECON_SCHEDULE_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
  selectedVessels: defaultParams.selectedVessels,
  downloading: false,
  stockReconStartDate: defaultParams.stockReconStartDate,
  stockReconEndDate: defaultParams.stockReconEndDate,
  sort: defaultParams.sort,
  sortField: defaultParams.sortField,
};

export const stockReconScheduleReducer = (
  state = initialState,
  action: StockReconScheduleActionTypes | ReadCriticalSpareSuccessAction,
): StockReconScheduleReducerState => {
  switch (action.type) {
    case StockReconScheduleActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        readList: API_REQUEST_STATE,
      };
    case StockReconScheduleActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        readList: API_SUCCESS_STATE,
        stockReconScheduleList: action.payload,
      };
    case StockReconScheduleActionTypeEnum.READ_LIST_FAIL:
      return {
        ...state,
        readList: API_FAIL_STATE,
        error: action.payload,
      };
    case StockReconScheduleActionTypeEnum.DOWNLOAD_DATA_REQUEST:
      return {
        ...state,
        downloading: true,
      };
    case StockReconScheduleActionTypeEnum.DOWNLOAD_DATA_SUCCESS:
    case StockReconScheduleActionTypeEnum.DOWNLOAD_DATA_FAIL:
      return {
        ...state,
        downloading: false,
      };
    default:
      return state;
  }
};
