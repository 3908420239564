export enum TransformationFieldEnum {
  VESSEL_NAME = 'vesselName',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  PARTS_INFO = 'partsInfo',
  PARTS_SCNX = 'partsInfo.spares',
  PARTS_ERP = 'partsInfo.external',
  ROB_SCNX = 'robInfo.spares',
  ROB_ERP = 'robInfo.external',
  ROB_INFO = 'robInfo',
  TRANSFORMATION_DURATION = 'transformationDuration',
  TIME_SPENT_APP = 'timeSpentInApp',
  STATUS = 'status',
}

export enum TransformationActivityStatsTypeEnum {
  Tagged = 'Tagged',
  CheckedIn = 'Checked In',
  CheckedOut = 'Checked Out',
  UpdatedQuantity = 'Updated Quantity',
  UpdatedLocation = 'Updated Location',
  DeleteTagged = 'Delete Tagged',
  DeleteCheckedIn = 'Delete Checked In',
}

export enum ReadListTransformationParamsSortFieldEnum {
  VESSEL_NAME = 'vesselName',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TRANSFORMATION_DURATION = 'transformationDuration',
  STATUS = 'status',
  PARTS_INFO_SPARES = 'partsInfo.spares',
  PARTS_INFO_EXTERNAL = 'partsInfo.external',
  ROB_INFO_SPARES = 'robInfo.spares',
  ROB_INFO_EXTERNAL = 'robInfo.external',
}

export enum ReadListTransformationParamsStatusEnum {
  COMPLETED = 'Completed',
  INPROGRESS = 'In Progress',
  NOT_STARTED = 'Not Started',
}

export enum FilterFormEnum {
  VESSEL_NAME = 'vesselName',
  TRANSFORMATION_DURATION_MIN = 'minDays',
  TRANSFORMATION_DURATION_MAX = 'maxDays',
}

export enum TransformationSortFieldEnum {
  Name = 'name',
  Manufacture = 'manufacture',
  PartNumber = 'partNumber',
  ErpParentEquipmentName = 'erpParentEquipmentName',
  RemainingOnBoard = 'remainingOnBoard',
  ErpRob = 'erpROB',
}

export enum TransformationFilterFieldEnum {
  LessShipRob = 'less-ship-rob',
  MoreShipRob = 'more-ship-rob',
}

export enum LocationTagTypeEnum {
  LOCATION = 'location',
  PART_QUANTITY = 'partQuantity',
  PART_ROB = 'partRob',
  EQUIPMENT_AT_LOCATION = 'hierarchyLevelEquipments',
}

export enum LocationTagSortEnum {
  LOCATION = 'location',
  PART_QUANTITY = 'partQuantity',
  PART_ROB = 'partRob',
  HIEARACHY_LEVEL_EQUIPMENTS_COUNT = 'hierarchyLevelEquipmentsCount',
}

export enum BreakdownOfCountsTypeEnum {
  CATEGORY = 'catagory',
  PROPELLER = 'propeller',
  ERP = 'erp',
}

export enum SystemSpareSummary {
  PROPELLER = 'ship',
  ERP = 'erp',
}

export enum CategoryOverview {
  NUMBER_OF_EQUIPMENT = 'numberOfEquipments',
  PARTS_QUANTITY = 'numberOfParts',
  TOTAL_ROB = 'totalRob',
}

export enum PartTypeEnum {
  NAME = 'name',
  MANUFACTURER = 'manufacture',
  PART_NUMBER = 'partNumber',
  PART_EQUIPMENT = 'erpParentEquipmentName',
  ROB = 'remainingOnBoard',
  ERP_ROB = 'erpROB',
}
