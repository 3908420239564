export enum InventoryFieldEnum {
  SPARE_ID = 'partId',
  NAME = 'name',
  MANUFACTURE = 'manufacture',
  PART_NUMBER = 'partNumber',
  PARENT_EQUIPMENT = 'parentEquipments',
  ROB = 'remainingOnBoard',
  DAYS_ON_BOARD = 'daysOnBoard',
  MIN_QTY = 'minQuantity',
  CRITICAL = 'isCritical',
  IMO = 'imoNo',
  VESSEL_NAME = 'vesselName',
  PARENT_NAME = 'parentName',
}

export enum InventoryTagStatusEnum {
  Pending = 'Tagged',
  Checked = 'Checked In',
  Consumed = 'Obsolete',
  DeleteTagged = 'Delete Tagged',
  DeleteCheckedIn = 'Delete Checked In',
}
export enum InventoryTagStatusLabelEnum {
  Pending = 'Pending Check In',
  Checked = 'Checked In',
  Consumed = 'Consumed',
}

export enum InventoryTagFieldEnum {
  TAG_ID = 'tagLabel',
  LOCATION = 'location',
  QTY = 'remainingQuantity',
  TAG_STATUS = 'tagStatus',
  DAYS_ON_BOARD = 'daysOnBoard',
  ITEM_ORDER = 'itemOrder',
}

export enum InventoryCriteriaFieldEnum {
  PARENT_EQUIPMENT_ID = 'parentEquipmentId',
  IS_CRITICAL = 'isCritical',
  NAME = 'name',
  MANUFACTURE = 'manufacture.raw',
  IS_ROB_BELOW_MIN = 'isRobBelowMin',
  REMAINING_ON_BOARD = 'remainingOnBoard',
  IS_SPARES_ROB = 'isSparesRob',
}

export enum ReadListInventoryParamsSortFieldEnum {
  CREATED_DATE = 'createdDate',
  NAME = 'name',
  REMAINING_ON_BOARD = 'remainingOnBoard',
  MANUFACTURE = 'manufacture',
  PART_NUMBER = 'partNumber',
  DAYS_ON_BOARD = 'daysOnBoard',
  MIN_QUANTITY = 'minQuantity',
  IMO_NO = 'imoNo',
  VESSEL_NAME = 'vesselName',
  QUANTITY = 'quantity',
  PARENT_NAME = 'parentName.name',
}

export enum InventoryStatisticsTypeEnum {
  PART_WITH_ROB = 'parts-with-rob',
  CS_AT_MIN_LEVEL = 'critical-at-min-level',
  POTENTIAL_PARTS = 'potential-parts',
  ROB_BY_EQUIPMENT = 'rob-by-equipment',
}

export enum PriceHistoryEnum {
  DOCUMENT_ID = 'sourceReferenceNumber',
  DOCUMENT_TYPE = 'sourceType',
  SUPPLIER = 'companyName',
  VESSEL_ID = 'imoNo',
  VESSEL_NAME = 'vesselName',
  PRICE = 'price',
  DATE = 'date',
}

export enum InventoryExpandDataEnum {
  LOADING = 'loading',
  ERROR = 'error',
}

export enum IncludeTagsEnum {
  YES = 'yes',
  NO = 'no',
}

export enum PromptText {
  START_TYPING = 'Start typing',
  NO_OPTIONS = 'No options',
}
