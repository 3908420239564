export enum TagStatKeyEnum {
  // api
  Tagged = 'Tagged',
  CheckedIn = 'Checked In',
  Obsolete = 'Obsolete',
  DeleteTagged = 'Delete Tagged',
  DeleteCheckedIn = 'Delete Checked In',
  Unused = 'Unused',

  // ConsumedTagged = DeleteTagged + DeleteCheckedIn + Obsolete
  ConsumedTagged = 'Consumed Tags',
}

export enum CalendarUnitEnum {
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export enum StatPanelLabelEnum {
  lowCriticalSpares = 'Low Critical Spares',
  posPendingDelivery = ' POs Pending Delivery',
  potentialPartsToRestock = 'Parts to Restock',
  partsPendingCheckIn = 'Pending Check In',
}

export enum TitleListVesselsTransformation {
  TRANSFORMED = 'Transformed',
  INPROGRESS = 'In Progress',
  UPCOMING = 'Upcoming',
}
